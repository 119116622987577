<script setup lang="ts">
  import {defineAsyncComponent} from 'vue';
  import {useI18n} from 'vue-i18n';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import useCpBem from '~/composables/useCpBem';
  import CpNotificationBannerPromoteApp from '~/components/cp/CpNotificationBanner/CpNotificationBannerPromoteApp.vue';

  const VOverlay = defineAsyncComponent(() => import('vuetify/components/VOverlay').then((module) => module.VOverlay));

  withDefaults(defineProps<{
    kind?: 'lostConnection'|'weakConnection'|'noMailApp'|'promoteApp'
    icon?: 'wifi_off'|'wifi'|'error'
    visible?: boolean
  }>(), {
    kind: 'lostConnection',
    icon: 'wifi_off',
    visible: true,
  });

  const emit = defineEmits(['notificationBannerEvent']);

  const {b, e} = useCpBem('cp-notification-banner');
  const {t} = useI18n();
</script>

<template>
    <VOverlay
        v-if="kind !== 'promoteApp'"
        persistent
        :model-value="true"
        min-width="100%"
        @notification-banner-event="emit('notificationBannerEvent')"
    >
        <div :class="b">
            <CpIconStandard :icon="icon" type="primary" />
            <div :class="e`container`">
                <CpText variant="body-regular">
                    <CpText variant="body-bold">
                        {{
                            kind==='lostConnection' ? t('lostTextBold') :
                            kind==='weakConnection' ? t('weakTextBold') : t('NoMailTextBold')
                        }}
                    </CpText>
                    {{
                        kind==='lostConnection' ? t('lostTextRegular') :
                        kind==='weakConnection' ? t('weakTextRegular') : t('noMailTextRegular')
                    }}
                    <CpText v-if="kind==='noMailApp'" variant="body-bold">
                        {{ t('noMailEmail') }}
                    </CpText>
                    <CpText v-if="kind==='noMailApp'" variant="body-regular">
                        {{ t('noMailTextRegularTwo') }}
                    </CpText>
                </CpText>
                <CpText
                    :class="e`decoration`"
                    variant="body-link"
                    type="primary"
                    @click="emit('notificationBannerEvent')"
                >
                    {{ kind==='lostConnection' ? t('lostButton') : t('weakButton') }}
                </CpText>
            </div>
        </div>
    </VOverlay>
    <CpNotificationBannerPromoteApp v-else-if="visible" />
</template>

<style scoped lang="scss">
        .cp-notification-banner {
        display: flex;
        padding: 16px;
        gap: 16px;
        align-items: center;
        background-color: white;
        border-radius: 0px 0px $cp-border-radius $cp-border-radius;
        margin-top: 72px;
        width: 100%;
        &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        }
        &__decoration {
        width: 100%;
        text-align: right;
        text-decoration-line: underline;
        cursor: pointer;
        }
        }
        </style>
        <i18n locale="es-US">
            lostTextBold: "¡Se ha perdido la conexión a internet!"
            lostTextRegular: ' Por favor, revisa tu conexión para seguir navegando.'
            weakTextBold: '¡Conexión a internet débil!'
            weakTextRegular: ' Por favor, revisa tu conexión para seguir navegando optimamente.'
            NoMailTextBold: '¡No se encontró una app de correo electrónico en tu dispositivo!'
            noMailTextRegular: ' Por favor, envía un correo electrónico a '
            noMailEmail: "app-support{'@'}cyberpuerta.mx"
            noMailTextRegularTwo: ' para reportar el problema que encontraste.'
            lostButton: 'Reintentar'
            weakButton: 'Ignorar'
        </i18n>
